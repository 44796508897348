/* row */

.row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-start-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.row-start-stretch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.row-end-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-end-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.row-end-stretch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
}

.row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-center-end {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.row-center-stretch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.row-around-start {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.row-around-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-around-end {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.row-around-stretch {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
}

.row-between-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.row-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-between-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.row-between-stretch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

/* col */

.col-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.col-start-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.col-start-stretch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.col-end-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.col-end-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.col-end-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.col-end-stretch {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
}

.col-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.col-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-center-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.col-center-stretch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.col-around-start {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.col-around-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-around-end {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.col-around-stretch {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
}

.col-between-start {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.col-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.col-between-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.col-between-stretch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

/* others */

.w-full {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.grow-0 {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.grow-2 {
  flex-grow: 2;
}

.grow-3 {
  flex-grow: 3;
}

.grow-4 {
  flex-grow: 4;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.blue {
  background-color: blue;
}

.h-full,
.h-100 {
  height: 100%;
}
