.wrapper {
  color: var(--color-bg-0);
  min-height: 100vh;
  color: var(--color-text);
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  /* opacity: 0.5; */
}

.uiContainer {
  width: 30%;
  /* background-color: aliceblue; */
  min-height: 100vh;
}

.imageContainer {
  /* background-color: aquamarine; */
  min-height: 100vh;
  overflow: hidden;
  padding-right: 2rem;
}

.formContainer {
  box-shadow: var(--shadow-box);
  border-radius: 2rem;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
}

.logoImage {
  width: 6rem;
  height: 6rem;
}

.title {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: var(--font-weight-semibold);
}

.subtitle {
  font-size: var(--font-size-big);
  font-weight: var(--font-weight-semibold);
}

.subtitle2 {
  font-size: var(--font-size-small-1);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-light-2);
}

.prompt {
  /* font-size: var(--font-size-small); */
  font-weight: var(--font-weight-semibold);
}

.errorLabel {
  width: 100%;
  text-align: right;
  font-size: var(--font-size-small-1);
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
  padding-right: 0.5rem;
}

.errorLabelSingle {
  width: 100%;
  text-align: center;
  font-size: var(--font-size-small-1);
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
  padding-right: 0.5rem;
}

.customInput {
  width: 100%;
  min-height: 3rem;
}

.customInput input {
  width: 100%;
  min-height: 3rem;
}

.customInput i {
  margin-top: -0.85rem !important;
}

.line {
  max-width: 20.625rem;
  background-color: var(--color-border);
  height: 1px;
}

.loginButton {
  min-height: 3rem;
}

.loginSlcButton {
  min-height: 3rem;
}

.floatingContainer {
  background-color: #efebe9;
  box-shadow: 0 0.2rem 0.625rem 0 rgba(76, 78, 100, 0.5);
}

.loginSection {
  width: 400px;
  padding-left: 60px;
  padding-right: 60px;
}

.bgSection,
.bgSection img {
  width: 600px;
  height: 600px;
}

.otherLoginPrompt {
  min-width: 150px;
  text-align: center;
  font-size: var(--font-size-small-1);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-light-2);
}
