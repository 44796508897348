body {
    /* font-family: var(--font-family-secondary); */
    /* line-height: 1.625rem; */
    /* color: var(--color-text); */
    /* font-weight: var(--font-weight-semibold); */
    font-family: var(--font-family);
    /* color: var(--color-text); */
}

html {
    background-color: var(--color-light-gray);
}